<template>
    <div class="flex column tinjiku">
        <Logo />
        <div class="box">
            <div class="header">
                <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
                <div class="title">
                    <img src="@/assets/imgs/icons/01.png" class="title-img" alt="">
                    <span class="text">{{ $t('home.navTitles.tinjiku') }}</span>
                </div>
                <a-icon class="info-icon" v-if="step==1" @click="step=0" type="info-circle" />
            </div>
            <div class="metaverse animate__animated animate__fadeIn">
                <img class="rotate-infinite" src="@/assets/imgs/metaverse.gif" alt="metaverse">
            </div>
            <div v-if="step==0" class="container-pd">
                <div class="tinjiku-info" v-html="tinjikuInfo.desc"></div>
                <div class="mg30 btn btn-primary" @click="step=1">捐贈建寺</div>
            </div>
            <div v-else class="container-pd">
                <a-row :gutter="16" class="nav-bars">
                    <a-col :span="8" v-for="p,i in prices" :key="i">
                        <div :class="p==form.amount?'btn-primary':'btn-primary-ghost'" class="price-btn" @click="choosePrice(p)">
                            {{ p }}
                        </div>
                    </a-col>
                </a-row>
                <!-- <a-input class="amount center" size="large" type="number" ref="amount" v-model="amount">
                </a-input> -->
                <a-input class="amount" type="number" size="large" v-model="form.amount">
                    <div slot="suffix" class="btn-btype-group">
                        <span class="btn-btype" :class="form.btype==0?'on':''" @click="form.btype=0">
                            BCT
                        </span>
                        <span class="btn-btype" :class="form.btype==1?'on':''" @click="form.btype=1">
                            USDT
                        </span>
                    </div>
                </a-input>
                <div class="mg30 font-bold btn-primary" @click="onSubmit">
                    {{$t('tinjiku.join')}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from '@/components/Logo.vue';
import Shelldao from "@/web3/tron/shelldao";

export default {
    name: "Tinjiku",
    data() {
        return {
            loading: false,
            step: 0,
            prices: [1,10,100,200,500,1000],
            tinjikuInfo: {
                desc: ''
            },
            userUsdtBalance: 0,
            userBctBalance: 0,
            approvedUsdtAmount: 0,
            approvedBctAmount: 0,
            form: {
                amount: 1,
                btype: 1,
                gtype: 'js',
                name: '匿名',
                tel: '匿名',
                desc: '佛光普照',
            },
        };
    },
    components: { Logo },
    mounted() {
        Shelldao.init((r)=>{
            r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
                this.userUsdtBalance = res.toNumber() / 1000000;
                console.log('usdt balance:', this.userUsdtBalance);
            });
            r.gBctToken.balanceOf(r.gWalletAddress).call().then((res)=>{
                this.userBctBalance = res.toNumber() / 1000000;
                console.log('bct balance:', this.userBctBalance);
            });
            Shelldao.getDonateApprovedAmount('USDT',(res)=>{
                let _approvedAmount=0;
                if(res.remaining===undefined){
                    _approvedAmount = res.toNumber();
                }else{
                    _approvedAmount = res.remaining.toNumber();
                }
                this.approvedUsdtAmount = _approvedAmount/1000000;
                console.log('approved usdt:', this.approvedUsdtAmount);
            });
            Shelldao.getDonateApprovedAmount('BCT',(res)=>{
                let _approvedAmount=0;
                if(res.remaining===undefined){
                    _approvedAmount = res.toNumber();
                }else{
                    _approvedAmount = res.remaining.toNumber();
                }
                this.approvedBctAmount = _approvedAmount/1000000;
                console.log('approved bct:', this.approvedBctAmount);
            })
        });
        this.getData();
    },
    methods: {
        async onSubmit() {
            if(this.loading) return;
            if(!this.form.amount){
                this.$message.error('请填写捐贈金額'); return;
            }
            let data = this.form;
            this.loading = true;
            this.$http.call(this.$http.api.gongxiu,{
                data
            }).then(res=>{
                // console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    console.log(resp.data)
                    this.handleDonate(resp.data)
                }else{
                    console.log(resp.data)
                    this.$message.error(resp.data);
                } 
            },res=>{
                this.loading = false;
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
        handleDonate(data){
            let btypes = ['BCT','USDT'];
            console.log('donate '+btypes[data.btype])
            let _this = this;
            if(data.btype == 0){
                if(this.approvedBctAmount <= data.amount*1000000){
                this.loading = true;
                Shelldao.donateApproveToken(data.amount*10000000, 'BCT',(res)=>{
                    console.log(res)
                    Shelldao.donate(0, data.amount*1000000, data.id, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                    });
                });
                }else{
                Shelldao.donate(0, data.amount*1000000, data.id, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                });
                }
            }else{
                if(this.approvedUsdtAmount <= data.amount*1000000){
                this.loading = true;
                Shelldao.donateApproveToken(data.amount*10000000, 'USDT',(res)=>{
                    console.log(res)
                    Shelldao.donate(1, data.amount*1000000, data.id, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                    });
                });
                }else{
                Shelldao.donate(1, data.amount*1000000, data.id, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                });
                }
            }
        },
        choosePrice(p){
            this.form.amount = p;
        },
        getData(){
            this.loading = true;
            this.$http.call(this.$http.api.tinjiku).then(res=>{
                console.log(res);
                let resp = res.data;
                if(resp.code=='200'){
                    this.tinjikuInfo = resp.data;
                }
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                this.loading = false;
            });
        },
    },
};
</script>
<style lang="less" scoped>
.tinjiku {
    width:100%;
    padding: 0 50px;
    text-align: center;
    height: 100%;
    .box{
        width: 100%;
        position: relative;
        flex: 1;
        margin-bottom: 30PX;
        overflow-y: scroll;
        overflow-x: hidden;
        .header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15PX;
            .back {
                position: absolute;
                left: 15PX;
                font-size: 20PX;
                color: #a8a8a8;
            }
            .title {
                font-weight: bold;
                font-size: 16PX;
                color: @primary-color;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title-img {
                    height: 50PX;
                }
            }
            .info-icon {
                position: absolute;
                right: 15PX;
                font-size: 20PX;
                color: #a8a8a8;
            }
        }
        .metaverse{
            padding: 16PX 28PX;
            img{
                width: 70%;
                margin: 0 auto;
            }
        }
        .container-pd {
            .tinjiku-info {
                text-align: left;
            }
            .price-btn {
                margin: 16PX 0;
            }
        }
        .amount{
            color: @primary-color;
            .btn-btype-group {
                padding: 5PX 0;
                border-radius: 15PX;
                overflow: hidden;
                font-weight: bold;
                .btn-btype {
                    background: @primary-color-shadow;
                    padding: 3PX 15PX;
                    color: @primary-color;
                }.on {
                    background: @primary-color;
                color: #fff;

                }
            }
        }        
    }
}
</style>
